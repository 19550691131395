.megamenu {
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll; /* dumb hack */
  max-height: calc(100vh - 200px);
  white-space: nowrap;
}

.absolute {
  position: absolute;
  left: 0;
}

.contentWrapper {
  display: flex;
}
