.navigation { 
  composes: flexCenter from '~css/alignment.css';
}

html[dir='ltr'] {
  .navigation { 
    &.withGap > *:not(:last-child) {
      margin-right: var(--gap);
    }
  }
}

html[dir='rtl'] {
  .navigation { 
    &.withGap > *:not(:last-child) {
      margin-left: var(--gap);
    }
  }
}