.list {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.absolute {
  position: absolute;
  bottom: 0;
}

.listLink {
  padding: 1rem;
}