.container {
  margin-bottom: 2rem;
  position: relative;
  width: 100%;
}

.toggles {
  composes: gridFallbackTwo from '~css/layout.css';
  composes: bodyXS from '~css/typography.css';
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  text-transform: uppercase;
}

.singleButton {
  grid-template-columns: 1fr
}

.clearButton {
  composes: flexCenterVertical from '~css/alignment.css';
  composes: bodyXS from '~css/typography.css';
  bottom: 0;
  position: absolute;
  transform: translateY(100%);
}

html[dir='ltr'] {
  .clearButton {
    left: 0;

    svg {
      margin-right: 5px;
    }
  }
}

html[dir='rtl'] {
  .clearButton {
    right: 0;

    svg {
      margin-left: 5px;
    }
  }
}