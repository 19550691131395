.areaSelector {
  &.valid {
    border: 1px solid var(--successColor);
    border-right: var(--standardInputBorderStyle);
  }

  &.invalid {
    border: 1px solid var(--errorColor);
    border-right: var(--standardInputBorderStyle);
  }
}

.inputsWrapper {
  display: grid;
  grid-template-columns: 1fr;

  &.isPhone {
    grid-template-columns: 100px 1fr;
  }
}

.realSelect {
  font-size: 0;
  color: transparent;

  option {
    color: var(--bodyColor);
    font-size: 1.6rem;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  select.decorativeSelect {
    pointer-events: none;
    position: absolute;
    left: 0;
    padding-left: 40px;
  }
}

.prefix {
  font-weight: 700;
}
