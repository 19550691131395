$border: 1px solid var(--greyOnLight);

.overflow {
  overflow-x: auto;
  width: 100%;
}

.container {
  width: 100%;
  
  th {
    padding: 0.5rem;
  }

  &.borders {
    border: $border;

    &:not(.rowsTable):not(.columnsTable) td {
      border: $border;
    }

    &.rowsTable td {
      border-top: $border;
      border-bottom: $border;
    }
    
    &.columnsTable td {
      border-left: $border;
      border-right: $border;
    }
  }
}

.selected {
  background-color: var(--greyLight);

  td {
    border-color: var(--greyOnLight);
  }
}

/* Size Props  */
.large {
  composes: bodyLarge from '~css/typography.css';
}

.regular {
  composes: bodyRegular from '~css/typography.css';
}

.small {
  composes: bodySmall from '~css/typography.css';
}

.xs {
  composes: bodyXS from '~css/typography.css';
}