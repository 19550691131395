.grid {
  display: grid;
  flex: 1;
  grid-gap: var(--gap);
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

html[dir='ltr'] {
  .flex {
    > *:not(:last-child) {
      margin-right: var(--gap);
    }
  }
}

html[dir='rtl'] {
  .flex {
    > *:not(:last-child) {
      margin-left: var(--gap);
    }
  }
}