.bar {
  composes: flexCenterHorizontal from '~css/alignment.css';
  align-items: center;
  flex-wrap: wrap;
  min-height: 8rem;
  text-align: center;
  gap: 2rem;

  /* stylelint-disable */
  #add-channel-button-2:empty {
    display: none;
  }
}

.controls {
  display: flex;

  button[type="submit"] ,
  input[name="email-signup-input"] {
    height: 4.5rem;
  }


  button[type="submit"] {
    padding: 0 2rem;
  }   
}

.newline {
  background-color: var(--bodyColor);
  color: white;
  padding: 2rem 4rem;
  margin: 2rem -4rem -4rem;
  width: calc(100% + 8rem);

  &:empty {
    display: none;
  }

  & label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  & label span {
    margin-left: 1rem;
  }

  & label div {
    font-size: var(--bodyXs); 
    padding: 0.5rem;
    text-align: left;
  }
} 


@media(--tabletMedium) {
  .bar {
    align-items: flex-start;
    flex-direction: column;
    text-align: initial;
    gap: 0;
  }

  .controls { 
    margin-top: 1rem;
    width: 100%;

    [class~=email-signup-input-wrapper] {
      width: 100%;
    }
  }

  .newline {
    margin-top: 4rem;
    padding: 2rem 4rem;
  }
}




