.container {
  > span {
    display: inline-block;
  }
}

.fromPrice {
  &:empty {
    display: none;
  }
}

.originalPrice {
  color: var(--colorOriginalPrice);
  text-decoration: line-through;
}

.currentPrice {
  color: var(--colorSale);
}

.percentageOff {
  color: var(--colorPercentageOff)
}

.tax {
  color: var(--greyInactive);
  margin-bottom: 5px;
}

html[dir='ltr'] {
  .container {
    > span:not(:last-of-type) {
      margin-right: 5px;
    }
  }
}

html[dir='rtl'] {
  .container {
    > span:not(:last-of-type) {
      margin-left: 5px;
    }
  }
}

/* Type Prop */

.large {
  composes: bodyLarge from '~css/typography.css';
}

.regular {
  composes: bodyRegular from '~css/typography.css';
}

.small {
  composes: bodySmall from '~css/typography.css';
}

.xs {
  composes: bodyXS from '~css/typography.css';
}
