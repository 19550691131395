$smallSquare: 17px;
$mediumSquare: 32px;

$largeSquare: 40px;

$xsCircle: 15px;
$smallCircle: 24px;
$mediumCircle: 28px;
$xMediumCircle: 35px;
$largeCircle: 40px;

/* Mobile Sizes */
$xsCircleMobile: 12px;

.swatch {
  background-size: cover;
  border: 1px solid var(--bodyBackgroundColor);
  border-radius: inherit;
  display: block;
  height: $mediumSquare;
  width: $mediumSquare;
}

.small {
  height: $smallSquare;
  width: $smallSquare;
}

.large {
  height: $largeSquare;
  width: $largeSquare;
}

.fill {
  height: 100%;
  position: absolute;
  width: 100%;
}

.circle {
  border-radius: 50%;
  border-width: 3px;
  height: $mediumCircle;
  width: $mediumCircle;

  &.xs {
    height: $xsCircle;
    width: $xsCircle;
  }

  &.small {
    height: $smallCircle;
    width: $smallCircle;
  }

  &.xmedium {
    height: $xMediumCircle;
    width: $xMediumCircle;
  }
  

  &.large {
    height: $largeCircle;
    width: $largeCircle;
  }

  &.fill {
    height: 100%;
    position: absolute;
    width: 100%;
  }
}

@media (--tabletMedium) {
  .circle {
    &.xs {
      border-width: 2px;
      height: $xsCircleMobile;
      width: $xsCircleMobile;
    }
  }
}
