.container {
  width: 100%;
  display: flex;
  position: relative;
}

.list {
  min-height: 40px;
  display: flex;
  gap: 8px;
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 10px 6px 0;
  position: relative;
}

.list > * {
  margin: 8px 0;
}

/* Applies styles if the device has a pointer and thus displays scrollbar */
@media (hover: hover) {
  .list {
    margin: 0 auto 4px;
    height: auto;
    padding-bottom: 4px;
  }

  .list::-webkit-scrollbar {
    height: 8px;
  }

  .list::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgb(224, 224, 224)
  }

  .list::-webkit-scrollbar-thumb:hover {
    background-color: rgb(160, 160, 160)
  }
}

.container::after {
  content: "";
  width: 10px;
  height: 100%;
  padding-top: 8px;
  position: absolute;
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0%), rgba(255, 255, 255, 1));
}
