.button {
  font-weight: 700;
  padding: 1rem 2rem;
}

.innerWrapper {
  display: inline-block;
}

.defaultStyle {
  .innerWrapper {
    border-bottom: 3px solid var(--greyLight);
  }
  

  &.active {
    .innerWrapper {
      border-bottom: 3px solid black;
    }
  }
}
