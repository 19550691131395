.inchToggle {
  composes: flexCenterVertical from '~css/alignment.css';
}


html[dir='ltr'] {
  .first {
    margin-right: 1rem;
  }

  .second {
    margin-left: 1rem;
  }
}

html[dir='rtl'] {
  .first {
    margin-left: 1rem;
  }

  .second {
    margin-right: 1rem;
  }
}
