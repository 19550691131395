.formFeildlabel {
  display: block;
}

.customisationContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.customisationInputLabel {
  display: flex;
  justify-content: space-between;
}

.errorText {
  color: var(--errorColor);
  font-size: small;
  display: block;
}

.swatchWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.errorInput {
  border-color: var(--errorColor);
}
