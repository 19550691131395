.button {
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
}

.optionsWrapper {
  padding-bottom: 2rem;
}


.applyWrapper {
  margin-top: 2rem;
}
