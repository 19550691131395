$size: 18px;

.valid,
.invalid {
  composes: flexCenter from '~css/alignment.css';
  border-radius: 50%;
  width: $size;
  height: $size;
}

.valid {
  background-color: var(--successColor);
}

.invalid {
  background-color: var(--errorColor);
}
