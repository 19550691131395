.container {
  composes: flexCenter from '~css/alignment.css';
  color: rgba(153, 153, 153, 1);
  letter-spacing: 0.5px;
  min-height: 100vh;
  min-width: 100vw;
  text-align: center;
}

.appError {
  max-width: 500px;
  padding: 2rem;
}

.section {
  margin-bottom: 2rem;
}

.heading {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 2rem;
}
