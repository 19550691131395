.button {
  composes: flexCenterVertical from '~css/alignment.css';
  composes: bodySmall from '~css/typography.css';
  margin-bottom: 1rem;
  text-align: left;
}

html[dir='ltr'] {
  .button {
    svg {
      margin-right: 0.5rem;
    }
  }

  .swatchWrapper {
    margin-right: 0.5rem;
  }
}

html[dir='rtl'] {
  .button {
    svg {
      margin-left: 0.5rem;
    }
  }

  .swatchWrapper {
    margin-left: 0.5rem;
  }
}
