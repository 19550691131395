.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalWrapper {
  width: 100%;
  max-width: 700px;
  position: relative;
  display: inline-block;
}
