.buttonWrapper {
  display: block;
}

.button {
  align-items: center;
  display: inline-flex;
  border: 1px solid transparent;
  justify-content: center;
  max-width: 100%;
  pointer-events: all;
  text-align: center;

  &.disabled {
    cursor: not-allowed;
  }
}

/* height prop */

$smallPadding: 0.25rem;
$mediumPadding: 0.5rem;
$largePadding: 1rem;

.verticalSmall {
  min-height: var(--buttonVertSmall);
  padding-top: $smallPadding;
  padding-bottom: $smallPadding;
}

.verticalMedium {
  min-height: var(--buttonVertMedium);
  padding-top: $mediumPadding;
  padding-bottom: $mediumPadding;
}

.verticalLarge {
  min-height: var(--buttonVertLarge);
  padding-top: $largePadding;
  padding-bottom: $largePadding;
}

/* Width prop */

.horizontalSmall {
  padding-left: var(--buttonHorizPaddingSmall);
  padding-right: var(--buttonHorizPaddingSmall);
}

.horizontalMedium {
  padding-left: var(--buttonHorizPaddingMedium);
  padding-right: var(--buttonHorizPaddingMedium);
}

.horizontalLarge {
  padding-left: var(--buttonHorizPaddingLarge);
  padding-right: var(--buttonHorizPaddingLarge);
}

.horizontalFull {
  width: 100%;
}

/* font size prop */

.fontXs {
  font-size: var(--bodyXs);
}

.fontSmall {
  font-size: var(--bodySmall);
}

.fontMedium {
  font-size: var(--bodyRegular);
}

.fontLarge {
  font-size: var(--bodyLarge);
}

/* radius props */
.borderRadiusSmall {
  border-radius: 1rem;
}

.borderRadiusMedium {
  border-radius: 2rem;
}

.borderRadiusLarge {
  border-radius: 4rem;
}

.borderRadiusRoundedSides {
  composes: roundedSides from '~css/utils.css';
}

/* singleLine Prop */

.lineHeightOne {
  composes: lineHeightOne from '~css/typography.css';
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}
