.tableContainer {
  font-size: var(--bodySmall);

  table {
    th {
      text-align: left;
    }

    td,
    th {
      border: 1px solid rgb(190, 190, 190);
      padding: 5px;
    }
  }

}
