.message {
  composes: bodySmall from '~css/typography.css';
  margin-top: 0.5rem;
  font-weight: 500;
}

.error {
  color: var(--errorColor)
}

.success {
  color: var(--successColor);
}

.warning {
  color: var(--warningColor);
}
