.large {
  composes: bodyLarge from '~css/typography.css';
}

.regular {
  composes: bodyRegular from '~css/typography.css';
}

.small {
  composes: bodySmall from '~css/typography.css';
}

.xs {
  composes: bodyXS from '~css/typography.css';
}

.primary {
  font-family: var(--fontPrimary);
}

.secondary {
  font-family: var(--fontSecondary);
}

.custom {
  font-family: var(--fontCustom);
}

.custom2 {
  font-family: var(--fontCustom2);
}

.custom3 {
  font-family: var(--fontCustom3);
}

.custom4 {
  font-family: var(--fontCustom4);
}

.htmlText {
  ol {
    padding-left: 20px;
  }

  ul {
    list-style-type: disc;
  }

  li > ol,
  ul {
    margin-bottom: 20px;
    padding-left: 30px;
  }

  strong {
    color: var(--strongColor);
  }

  h4 + br {
    display: none;
  }

  a {
    text-decoration: underline;
  }
}

.textBlock {
  li,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    max-width: calc(100vw - (var(--mobilePadding) *2));
  }

  th,
  td {
    padding: 10px;
  }

  table,
  th,
  td {
    border: 1px solid var(--greyMedium);
  }

  table {
    margin: 30px 0;
    
    @media (--mobileLarge) {
      min-width: 450px;
    }
  }

  a {
    @mixin textLink;
  }
}