$border: 1px solid var(--greyLight);

.title {
  padding: 20px;
  text-align: center;
}

.zeroState {
  composes: lineHeightSmall from '~css/typography.css';
  border-top: $border;
  color: var(--greyDark);
  text-align: center;
}

.buttonWrapper {
  border-top: $border;
  padding: 20px;
}

.button {
  composes: bodyXS from '~css/typography.css';
  width: 100%;
}

.viewport {
  border-bottom: $border;
  border-top: $border;
  max-height: 35vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.total {
  composes: bodySmall from '~css/typography.css';
  color: var(--greyDark);
  padding: 20px;
  text-align: center;
  text-transform: uppercase;
}

.totalPrice {
  color: black;
  margin-left: 0.5rem;
}
