.wrapper {
  top: 0;
  width: 290px;
  position: fixed;
  height: 100%;
  composes: aboveAlways from '~css/z-index.css';
}

.content {
  composes: lineHeightOne from '~css/typography.css';
  overflow-y: auto;
  height: 100%;
}

.closeHeader {
  position: absolute;
  margin-top: 5px;
  padding: 5px;
  composes: aboveAlways from '~css/z-index.css';
  background-color: white;
  border-radius: 20px;
  height: 40px;
}

.start .closeHeader {
  left: 100%;
  margin-left: 5px;
}

.end .closeHeader {
  right: 100%;
  margin-right: 5px;
}

html[dir='ltr'] {
  .wrapper {
    &.start {
      transform: translateX(-100%);
    }

    &.end {
      right: 0;
      transform: translateX(100%);
    }

    &.out {
      transition: all 0.2s ease;
      transform: translateX(0);
    }
  }
}

html[dir='rtl'] {
  .wrapper {
    &.start {
      transform: translateX(100%);
    }

    &.end {
      left: 0;
      transform: translateX(-100%);
    }

    &.out {
      transition: all 0.2s ease;
      transform: translateX(0);
    }
  }
}