*,
*::before,
*::after {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0 0 1rem;
}

a {
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

button {
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  letter-spacing: inherit;
  line-height: inherit; /* stylelint-disable-line property-disallowed-list */
  padding: 0;
  text-transform: inherit;
}

input[type="submit"] {
  appearance: none;
  border-radius: 0;
  cursor: pointer;
}

input[type="radio"] {
  appearance: none;
}

input,
select,
textarea {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
}
