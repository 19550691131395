.button {
  composes: flexCenterVertical from '~css/alignment.css';
  composes: bodySmall from '~css/typography.css';
  height: 45px;
  padding: 10px 15px;
  width: 100%;

  &:not(:first-of-type) {
    border-top: 1px solid var(--greyLight);
  }
}

html[dir='ltr'] {
  .iconWrapper {
    margin-right: 1rem;
  }
}

html[dir='rtl'] {
  .iconWrapper {
    margin-left: 1rem;
  }
}
