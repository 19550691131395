.supportLink {
  top: -50px;
  position: absolute;
  z-index: 1;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }
}
