.skeleton {
  background: var(--skeletonColor);
}

.animate {
  background: var(--skeletonColor);
  max-width: 100%;
  /* background-image: linear-gradient(to right, var(--greyLight) 5%, white 10%, var(--greyLight) 20%);
  background-size: 200% auto; */

  :global { /* stylelint-disable-line selector-pseudo-class-no-unknown */
    /* animation: shine 1s infinite; */
  }
}

@keyframes :global(shine) {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: -100% 0;
  }
}
