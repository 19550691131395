.wrapper {
  position: relative;
}

.decorativeInput {
  composes: flexCenter from '~css/alignment.css';
  border: 1px solid var(--greyOnLight);
  border-radius: 10px;
  width: 100%;

  &.focussed {
    border-color: blue;
  }

  &:after {
    content: "";
    padding-top: 100%;
  }
}

.codeInput {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;

  &:focus {
    outline: 0;
  }
}