.iconWrapper {
  composes: flexCenter from '~css/alignment.css';
}

html[dir='ltr'] {
  .iconWrapper {
    margin-right: 0.8rem;
  }
}

html[dir='rtl'] {
  .iconWrapper {
    margin-left: 0.8rem;
  }
}
