.header {
  border-bottom: 1px solid var(--greyOnLight);
}

html[dir='ltr'] {
  .header {
    th {
      border-right: 1px solid var(--greyOnLight)
    }
  }
}

html[dir='rtl'] {
  .header {
    th {
      border-left: 1px solid var(--greyOnLight)
    }
  }
}
