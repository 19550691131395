.cookieMessage {
  composes: flexCenterHorizontal from '~css/alignment.css';
  composes: bodySmall from '~css/typography.css';
  background-color: var(--cookieMessageBackgroundColor);
  bottom: 0;
  box-shadow: 0 -2px 15px 2px rgba(0, 0, 0, 0.05);
  color: var(--cookieMessageTextColor);
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 1001;
}

.wrapper {
  composes: flexCenterHorizontal from '~css/alignment.css';
}

.link {
  text-decoration: underline;
}

.closeIcon {
  color: var(--cookieMessageCloseIconColor);
  display: flex;
}

html[dir="ltr"] {
  .link {
    margin-left: 5px;
  }

  .closeIcon {
    margin-left: 15px;
  }
}

html[dir="rtl"] {
  .link {
    margin-right: 5px;
  }

  .closeIcon {
    margin-right: 15px;
  }
}