.wrapper {
  position: relative;

  svg {
    position: absolute;
    top: calc(var(--standardInputHeight) / 2);
    left: 1.5rem;
    transform: translateY(-50%)
  }
}

.input {
  background-color: var(--standardInputBackgroundColor);
  border: var(--standardInputBorderStyle);
  font-size: var(--standardInputFontSize);
  height: var(--standardInputHeight);
  padding: 0 2rem;
  text-align: left;
  width: 270px;

  &.error {
    border-color: var(--errorColor);
  }

  &::placeholder {
    color: var(--greyInactive);
    font-size: 1.4rem;
  }

  & ~ [class~=email-signup-input-error] {
    text-align: left;
  }
}

@media(--tabletMedium) {
  .input {
    width: 100%;
  }
}
