.iconLabel {
  font-size: 1.2rem;
  margin-top: 8px;
  white-space: nowrap;
}

@media (--tabletMedium) {
  .iconLabel {
    display: none;
  }
}
