.link {
  margin-bottom: 1rem;
  display: inline-block;
}

html[dir='ltr'] {
  .link {
    margin-right: 1rem;
  }
}

html[dir='rtl'] {
  .link {
    margin-left: 1rem;
  }
}