.swatchSet {
  display: inline-flex;
  flex-wrap: wrap;
}

.wrapper {
  display: flex;
}

.additional {
  composes: flexCenter from '~css/alignment.css';
  composes: bodySmall lineHeightOne from '~css/typography.css';
  color: var(--additionalSwatchCountColor);
}

.wrapper,
.additional {
  margin-bottom: 5px;
}

html[dir='ltr'] {
  .wrapper {
    margin-right: 5px;

    /* Props: swatchSize */
    &.xs {
      margin-right: 10px;
    }
  }
}

html[dir='rtl'] {
  .wrapper {
    margin-left: 5px;

    /* Props: swatchSize */
    &.xs {
      margin-left: 10px;
    }
  }
}
