$buttonSize: 50px;

.container {
  composes: flexCenter from '~css/alignment.css';
  composes: aboveMenu  from '~css/z-index.css';
  background-color: var(--scrollUpBackgroundColor);
  border-radius: 50%;
  border: var(--scrollUpBorder);
  bottom: 2vw;
  box-shadow: var(--scrollUpShadow);
  color: var(--scrollUpIconColor);
  height: $buttonSize;
  opacity: 0;
  position: fixed;
  right: 2vw;
  transition: all 0.2s ease-out;
  width: $buttonSize;
  pointer-events: none;

  @media (--tabletSmall) {
    bottom: 5vw;
    right: 5vw;
  }
}

.visible {
  opacity: 1;
  pointer-events: all;
}
