.navigation {
  display: flex;
  white-space: nowrap;
}

.absolute {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
}

.sublabel {
  margin-top: 0.5rem;
}

.bannerLink {
  padding: 1rem;
}

.labelWrapper {
  pointer-events: none; /* so onMouseLeave doesn't get triggered by this or any of its children */
}

html[dir='ltr'] {
  .navigation {
    > *:not(:last-child) {
      margin-right: var(--gap);
    }
  }
}

html[dir='rtl'] {
  .navigation {
    > *:not(:last-child) {
      margin-left: var(--gap);
    }
  }
}
