.selectWrapper {
  position: relative;
}

.selectButton {
  display: inline-flex;
  text-align: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  color: var(--colorActivePrimary);
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgb(214, 214, 214) inset;
  padding: 1rem 3rem 1rem 1.5rem;
  line-height: 22.4px; /* stylelint-disable-line property-disallowed-list */

  &:disabled {
    color: var(--greyInactive);
    cursor: not-allowed;
  }
}

[data-is-open="true"] .selectIcon {
  transform: translateY(-50%) rotate(180deg);
}

.selectIcon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
