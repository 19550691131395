.footer {
  background-color: var(--footerBg);
  border-top: var(--footerBorderTop);
  padding-bottom: 30px;
  padding-top: 50px;

  h3,h4 {
    color: var(--footerTitleColor);
    text-transform: var(--variableCasing);
  }

  ul {
    color: var(--footerLinksColor);
  }
}

.footerBottom {
  composes: flexCenterVertical from '~css/alignment.css';
  background-color: var(--footerBottomBg);
  border-top: var(--footerBorderTop); 
  flex-direction: column;
  padding: 3rem 0;
  text-align: center;
}

.footerCopy {
  composes: bodyXS from '~css/typography.css';
  color: var(--footerCopyColor);

  a {
    color: var(--footerCopyColor);
    display: inline-flex;
    justify-self: center;
  }
}

.paymentIconsContainer {
  margin-bottom: 2rem;
}

.footerCopyLink {
  color: black;
  margin: 0 5px;
  text-decoration: underline;
}

.footerCountrySelect {
  display: flex;
  justify-content: center;
}

.socialIconsContainer {
  margin-top: 3rem;
}

@media (--tabletMedium) {
  .footerBottom {
    display: block;
  }

  .footerCopy {
    > span:last-of-type {
      display: inline;
    }
  }

  .paymentIconsContainer {
    margin-left: 0;
  }

  .paymentWrapper:first-of-type {
    margin-left: 0;
  }
}
