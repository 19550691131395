$padding: 1.5rem;

.button {
  composes: flexCenterVertical from '~css/alignment.css';
  padding: 1rem;
  width: 100%;
}

.iconWrapper {
  background-color: white;
  border-radius: 50%;
  padding: 0.25rem;
}

html[dir='ltr'] {
  .iconWrapper {
    margin-right: 1rem;
  }

  .angleWrapper {
    margin-left: auto;
    margin-right: 1rem;
  }
}

html[dir='rtl'] {
  .iconWrapper {
    margin-left: 1rem;
  }
  
  .angleWrapper {
    margin-right: auto;
    margin-left: 1rem;
  }
}

.expandableArea {
  padding: 0 $padding $padding;
}