.accordionMenu {
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows .5s ease-in-out;
  border-bottom: 1px solid rgb(238, 238, 238);

}

.accordionMenu:not([hidden]) {
  border-bottom: 1px solid rgb(238, 238, 238);
}

.accordionButton {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding: 16px 0;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.accordionButton:first-child {
  border-top: 1px solid rgb(238, 238, 238);
}

.accordionMenu > div {
  overflow: hidden;
}

.accordionMenu[hidden] {
  grid-template-rows: 0fr;
}
