.container {
  composes: flexCenter from '~css/alignment.css';
  flex-direction: column;

  svg {
    fill: var(--favoriteIconColorFill);
  }

  .empty {
    svg {
      fill: var(--favoriteIconColorEmpty);
    }
  }
}
