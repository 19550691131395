$gap: 60px;
$margin: 2rem;

.modal {
  composes: flexCenter from '~css/alignment.css';
  composes: aboveAlways  from '~css/z-index.css';
  background-color: rgba(245, 245, 245, 0.7);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.accessibilityMode {
  a:focus-visible,
  button:focus-visible,
  input:focus-visible,
  select:focus-visible {
    @mixin focusVisible;
  }
}

.close {
  composes: lineHeightOne from '~css/typography.css';
  composes: aboveAlways  from '~css/z-index.css';
  position: absolute;
  top: $margin;
}

.contentWrapper {
  background: var(--bodyBackgroundColor);
  position: relative;
  max-width: calc(100vw - $gap);
}

.content {
  max-height: calc(100vh - $gap);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable both-edges;
}

@media (--tabletMedium) {
  .mobileFullScreen {
    .contentWrapper {
      max-width: 100%;
    }

    .content {
      max-height: 100vh;
    }
  }
}

@media (--mobileLarge) {
  .mobileFullScreen {
    .content {
      width: 100vw;
      height: 100vh;
    }
  }
}

html[dir='ltr'] {
  .close {
    right: $margin;
  }
}

html[dir='rtl'] {
  .close {
    left: $margin;
  }
}
