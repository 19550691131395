/* LINE HEIGHT */

.lineHeightOne {
  line-height: 1; /* stylelint-disable-line property-disallowed-list */
}

.lineHeightSmall {
  line-height: 1.3; /* stylelint-disable-line property-disallowed-list */
}

.lineHeightNormal {
  line-height: 1.6; /* stylelint-disable-line property-disallowed-list */
}

/* HEADINGS */

.headingXXL {
  font-size: var(--headingXxl);
  font-weight: var(--headingFontWeight);
}

.headingXL {
  font-size: var(--headingXl);
  font-weight: var(--headingFontWeight);
}

.headingLarge {
  font-size: var(--headingLarge);
  font-weight: var(--headingFontWeight);
  letter-spacing: var(--headingLargeLetterSpacing);

  @media (--tabletMedium) {
    font-size: 3rem;
  }

  @media (--mobileLarge) {
    font-size: 2.6rem;
  }
}

.headingMedium {
  font-size: var(--headingMedium);
  font-weight: var(--headingFontWeight);
  letter-spacing: var(--headingMediumLetterSpacing);

  @media (--mobileLarge) {
    font-size: 2.2rem;
  }
}

.headingSmall {
  font-size: var(--headingSmall);
  font-weight: var(--headingSmallFontWeight);
  letter-spacing: var(--headingSmallLetterSpacing);
}

.headingXS {
  font-size: var(--headingXs);
  font-weight: var(--headingXsFontWeight);
  letter-spacing: var(--headingXsLetterSpacing);
}

.headingXSRegular {
  font-size: var(--headingXs);
  font-weight: 500;
  letter-spacing: 1px;
}

/* SUBLINES */

.sublineXXL {
  font-size: var(--sublineXxl);
}

.sublineXL {
  font-size: var(--sublineXl);
}

.sublineLarge {
  font-size: var(--sublineLarge);
}

.sublineMedium {
  font-size: var(--sublineMedium);
}

.sublineSmall {
  font-size: var(--sublineSmall);
}

.sublineXS {
  font-size: var(--sublineXs);
}

/* BODY */

.bodyLarge {
  font-size: var(--bodyLarge);
}

.bodyRegular {
  font-size: var(--bodyRegular);
}

.bodySmall {
  font-size: var(--bodySmall);
}

.bodyXS {
  font-size: var(--bodyXs);
}

.bodyXXS {
  font-size: var(--bodyXxs);
}
