@media (--aboveTabletMedium) {
  .pageNav {
    width: 240px;
  }

  .desktopHeader {
    margin-bottom: 2rem;
  }

  .menuToggle {
    display: none;
  }
}

@media (--tabletMedium) {
  .desktopHeader {
    display: none;
  }

  .container {
    background-color: var(--greyLight);
    margin-bottom: 3rem;
  }

  .menuToggle {
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    width: 100%;
  }

  .pageNav {
    display: none;
    padding: 2rem 3rem 3rem;
    column-count: 2;

    &.open{
      display: block;
    }
  }
}

html[dir='ltr'] {
  .subNav {
    padding-left: 1rem;
  }

  @media (--aboveTabletMedium) {
    .pageNav {
      padding-right: 20px;
    }
  }
}

html[dir='rtl'] {
  .subNav {
    padding-right: 1rem;
  }

  @media (--aboveTabletMedium) {
    .pageNav {
      padding-left: 20px;
    }
  }
}