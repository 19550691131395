.splash {
  display: flex;
  flex-wrap: nowrap;
}

.photo {
  min-height: 75vh;
  width: 100%;

  img {
    height: 100%;
    max-height: none;
    max-width: none;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }
}

@media(--tabletMedium) {
  .splash {
    flex-wrap: wrap;
  }

  .photo {
    min-height: auto;
    height: 170px
  }
}



