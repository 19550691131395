:root {
  --layoutXxxxs: 0.5rem;
  --layoutXxxs: 1rem;
  --layoutXxs: 1.5rem;
  --layoutXs: 2rem;
  --layoutSmall: 3rem;
  --layoutMedium: 4rem;
  --layoutLarge: 8rem;
  --layoutXl: 16rem;
  --layoutXxl: 25rem;
}

.mainBody {
  min-height: 60vh;
  padding-top: var(--spacingMainBody);
  padding-bottom: calc(var(--spacingLarge) * 2);
  position: relative;
}

.widthContain {
  margin-left: auto;
  margin-right: auto;
  max-width: calc(100vw - (var(--tabletPadding) * 2));

  @media (--mobileLarge) {
    max-width: calc(100vw - (var(--mobilePadding) * 2));
  }
}

.widthContainPadding {
  padding-left: var(--tabletPadding);
  padding-right: var(--tabletPadding);

  @media (--mobileLarge) {
    padding-left: var(--mobilePadding);
    padding-right: var(--mobilePadding);
  }
}

/* ComponentContainers */

.siteContainerFlush {
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
}

.siteContainerFull {
  composes: widthContainPadding;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
}

.siteContainerLarge {
  composes: widthContain;
  width: var(--siteContainerLarge);
}

.siteContainerMedium {
  composes: widthContain;
  width: var(--siteContainerNormal);
}

.siteContainerSmall {
  composes: widthContain;
  width: var(--siteContainerSmall);
}

.siteContainerXs {
  composes: widthContain;
  width: var(--siteContainerXs);
}

.siteContainerXxs {
  composes: widthContain;
  width: var(--siteContainerXxs);
}

/* Component Paddings */

.paddingHorizontalXxxxs {
  padding-right: var(--layoutXxxxs);
  padding-left: var(--layoutXxxxs);
}

.paddingHorizontalXxxs {
  padding-left: var(--layoutXxxs);
  padding-right: var(--layoutXxxs);
}

.paddingHorizontalXxs {
  padding-left: var(--layoutXxs);
  padding-right: var(--layoutXxs);
}

.paddingHorizontalXs {
  padding-left: var(--layoutXs);
  padding-right: var(--layoutXs);
}

.paddingHorizontalSmall {
  padding-left: var(--layoutSmall);
  padding-right: var(--layoutSmall);
}

.paddingHorizontalMedium {
  padding-left: var(--layoutMedium);
  padding-right: var(--layoutMedium);
}

.paddingHorizontalLarge {
  padding-left: var(--layoutLarge);
  padding-right: var(--layoutLarge);
}

.paddingHorizontalXl {
  padding-left: var(--layoutXl);
  padding-right: var(--layoutXl);
}

.paddingHorizontalXxl {
  padding-left: var(--layoutXxl);
  padding-right: var(--layoutXxl);
}

.paddingVerticalXxxxs {
  padding-top: var(--layoutXxxxs);
  padding-bottom: var(--layoutXxxxs);
}

.paddingVerticalXxxs {
  padding-top: var(--layoutXxxs);
  padding-bottom: var(--layoutXxxs);
}

.paddingVerticalXxs {
  padding-top: var(--layoutXxs);
  padding-bottom: var(--layoutXxs);
}

.paddingVerticalXs {
  padding-top: var(--layoutXs);
  padding-bottom: var(--layoutXs);
}

.paddingVerticalSmall {
  padding-top: var(--layoutSmall);
  padding-bottom: var(--layoutSmall);
}

.paddingVerticalMedium {
  padding-top: var(--layoutMedium);
  padding-bottom: var(--layoutMedium);
}

.paddingVerticalLarge {
  padding-top: var(--layoutLarge);
  padding-bottom: var(--layoutLarge);
}

.paddingVerticalXl {
  padding-top: var(--layoutXl);
  padding-bottom: var(--layoutXl);
}

.paddingVerticalXxl {
  padding-top: var(--layoutXxl);
  padding-bottom: var(--layoutXxl);
}

/* Component Margins */

.marginBottomXxxxs {
  margin-bottom: var(--layoutXxxxs);
}

.marginBottomXxxs {
  margin-bottom: var(--layoutXxxs);
}

.marginBottomXxs {
  margin-bottom: var(--layoutXxs);
}

.marginBottomXs {
  margin-bottom: var(--layoutXs);
}

.marginBottomSmall {
  margin-bottom: var(--layoutSmall);
}

.marginBottomMedium {
  margin-bottom: var(--layoutMedium);
}

.marginBottomLarge {
  margin-bottom: var(--layoutLarge);
}

.marginBottomXl {
  margin-bottom: var(--layoutXl);
}

/** VERTICAL **/

.marginVerticalXxxxs {
  margin-top: var(--layoutXxxxs);
  margin-bottom: var(--layoutXxxxs);
}

.marginVerticalXxxs {
  margin-top: var(--layoutXxxs);
  margin-bottom: var(--layoutXxxs);
}

.marginVerticalXxs {
  margin-top: var(--layoutXxs);
  margin-bottom: var(--layoutXxs);
}

.marginVerticalXs {
  margin-top: var(--layoutXs);
  margin-bottom: var(--layoutXs);
}

.marginVerticalSmall {
  margin-top: var(--layoutSmall);
  margin-bottom: var(--layoutSmall);
}

.marginVerticalMedium {
  margin-top: var(--layoutMedium);
  margin-bottom: var(--layoutMedium);
}

.marginVerticalLarge {
  margin-top: var(--layoutLarge);
  margin-bottom: var(--layoutLarge);
}

.marginVerticalXl {
  margin-top: var(--layoutXl);
  margin-bottom: var(--layoutXl);
}

.marginVerticalXxl {
  margin-top: var(--layoutXxl);
  margin-bottom: var(--layoutXxl);
}

/* HORIZONTAL */

.marginHorizontalXxxxs {
  margin-left: var(--layoutXxxxs);
  margin-right: var(--layoutXxxxs);
}

.marginHorizontalXxxs {
  margin-left: var(--layoutXxxs);
  margin-right: var(--layoutXxxs);
}

.marginHorizontalXxs {
  margin-left: var(--layoutXxs);
  margin-right: var(--layoutXxs);
}

.marginHorizontalXs {
  margin-left: var(--layoutXs);
  margin-right: var(--layoutXs);
}

.marginHorizontalSmall {
  margin-left: var(--layoutSmall);
  margin-right: var(--layoutSmall);
}

.marginHorizontalMedium {
  margin-left: var(--layoutMedium);
  margin-right: var(--layoutMedium);
}

.marginHorizontalLarge {
  margin-left: var(--layoutLarge);
  margin-right: var(--layoutLarge);
}

.marginHorizontalXl {
  margin-left: var(--layoutXl);
  margin-right: var(--layoutXl);
}

.marginHorizontalXxl {
  margin-left: var(--layoutXxl);
  margin-right: var(--layoutXxl);
}

.flexGrid {
  --flexGridGap: 1rem;
  display: flex;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;

  > * {
    flex: 0 0 4rem;
    margin-bottom: var(--flexGridGap);
  }
}

html[dir='ltr'] {
  .flexGrid {
    > * {
      margin-right: var(--flexGridGap);
    }
  }
}

html[dir='rtl'] {
  .flexGrid {
    > * {
      margin-left: var(--flexGridGap);
    }
  }
}

/** GRID **/

.gridFallback {
  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: grid;
  }

  > * {
    min-width: 0; /* https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items */
  }
}

.gridFallbackTwo {
  composes: gridFallback;

  > * {
    flex-basis: 50%;
  }
}

.gridFallbackThree {
  composes: gridFallback;

  > * {
    flex-basis: 33.333333%;
  }
}

.gridFallbackFour {
  composes: gridFallback;

  > * {
    flex-basis: 25%;

    @media (--tabletMedium) {
      flex-basis: 50%;
    }
  }
}

.gridFallbackFive {
  composes: gridFallback;

  > * {
    flex-basis: 20%;

    @media (--tabletMedium) {
      flex-basis: 50%;
    }
  }
}

.gridFallbackSix {
  composes: gridFallback;

  > * {
    flex-basis: 16.666667%;

    @media (--tabletMedium) {
      flex-basis: 50%;
    }
  }
}

.gridFallbackThreeToOne {
  composes: gridFallback;

  > :first-of-type {
    flex-basis: 75%;
  }

  > :last-of-type {
    flex-basis: 25%;
  }

  @media (--tabletMedium) {
    > :first-of-type,
    > :last-of-type {
      flex-basis: 100%;
    }
  }
}

.gridFallbackOneToFour {
  composes: gridFallback;

  > :first-of-type {
    flex-basis: 20%;
  }

  > :last-of-type {
    flex-basis: 80%;
  }

  @media (--tabletMedium) {
    > :first-of-type,
    > :last-of-type {
      flex-basis: 100%;
    }
  }
}
