.container {
  display: flex;
  white-space: nowrap;
}

.option {
  color: var(--greyInactive);
  cursor: pointer;
  font-size: var(--linkXs);
  position: relative;

  &::before {
    position: absolute;
    bottom: 0;
  }
}

html[dir='ltr'] {
  .option {
    &:not(:first-child)::before {
      content: '/';
      left: -15px;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

html[dir='rtl'] {
  .option {
    &:not(:last-child)::before {
      content: '\\';
      left: -12px;
    }

    &:not(:last-child) {
      margin-left: 20px;
    }
  }
}
