
.wrapper {
  height: 100%;
}

.inner {
  /* stylelint-disable declaration-no-important */
  position: sticky !important;
  transition: top 550ms ease-in;
  will-change: top;
}
