.buttonContainer {
  margin-bottom: 0.5rem;
  perspective: 1000px; 
  height: 4rem;
  position: relative;
  width: 100%;

  &:focus-within .front {
    opacity: 0.75;
  }
}

.button {
  height: 100%;
  transform-style: preserve-3d; 
  transition: transform 0.6s; 
  width: 100%;
  will-change: transform;  
}

.reveal {
  transform: rotateX(90deg); 

  .front {
    transform: scale(0.9)
  }
}

.front,
.back {
  backface-visibility: hidden; 
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.front {
  align-items: center;
  background: var(--footerBg);
  /* stylelint-disable-next-line */
  border: none !important;
  color: var(--footerTitleColor);
  cursor: pointer;  
  display: flex;
  font-size: var(--productCardTitleFontSize);
  justify-content: center;
  /* stylelint-disable-next-line */
  outline: 0 !important;
  padding: 0.5rem 0.75rem;
  text-align: center;
  text-wrap: nowrap;
  transform-origin: top;
  transition: transform,opacity 0.6s;
}

.back {
  background: var(--footerTitleColor);
  color: inherit;
  line-height: 1; /* stylelint-disable-line property-disallowed-list */
  transform: rotateX(-90deg) translateY(50%) scale(0.99);
  transform-origin: bottom;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    white-space: nowrap;
    width: 100%;
  }
}