.container {
  composes: lineHeightOne from '~css/typography.css';
  display: flex;
  font-weight: 500;
  margin-bottom: var(--spacingSmall);
}

.small {
  composes: bodySmall from '~css/typography.css';
}

html[dir='ltr'] {
  .container > :first-child {
    margin-right: 5px;
  }
}

html[dir='rtl'] {
  .container > :first-child {
    margin-left: 5px;
  }
}