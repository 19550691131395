.toggle {
  display: block;
}

.siteSearchBarWrapper {
  composes: overlayCard from '~css/utils.css';
  padding: 2rem;
  position: absolute;
  transform: translateY(100%);
  width: 100%;
  bottom: 0;
  left: 0;
}