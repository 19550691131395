.disclaimer {
  font-size: var(--bodyXs);
  text-align: center;
}

.controls {
  margin-top: 1rem;
  width: 100%;

  [class~=email-signup-input],
  [class~=email-signup-submit] {
    height: var(--standardInputHeight);
    width: 100%;
  }

  [class~=email-signup-input] {
    margin-bottom: 1rem;
  }

  [class~=email-signup-submit] {
    margin-bottom: 2rem;
  }
}
