.menu {
  background-color: var(--bodyBackgroundColor);
  border: 1px solid;
  border-radius: var(--standardInputBorderRadius);
  max-height: 250px;
  overflow: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;

  &:empty {
    height: 0;
    opacity: 0;
  }
}
