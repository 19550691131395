.container {
  position: relative;
}

.tooltip {
  composes: lineHeightSmall bodyXS from '~css/typography.css';
  composes: overlayCard from '~css/utils.css';
  composes: aboveMenu from '~css/z-index.css';

  padding: 20px;
  width: 300px;

  &.top {
    bottom: 100%;
  }
  
  &.bottom {
    top: 100%;
  }

  @media(--tabletMedium) {
    &.override {
      bottom: auto;
      left: 50%;
      max-width: 90vw;
      min-width: 35vw;
      position: fixed;
      right: auto;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.close {
  position: absolute;
  top: 10px;
}

html[dir='ltr'] {
  .container {
    margin-left: 5px;
  }

  .tooltip {
    &.start {
      right: 100%;
    }

    &.end {
      left: 100%;
    }
  }

  .close {
    right: 10px;
  }
}

html[dir='rtl'] {
  .container {
    margin-right: 5px;
  }

  .tooltip {
    &.start {
      left: 100%;
    }

    &.end {
      right: 100%;
    }
  }

  .close {
    left: 10px;
  }
}

html[dir] {
  @media(--tabletMedium) {
    .tooltip.start {
      left: 50%;
    }

    .tooltip.end {
      right: unset;
    }
  }
}