
.modalWrapper {
  padding: 35px 35px 45px;
  width: 400px;
}

@media (max-width: 480px) {
  .modalWrapper {
    width: 100%;
    padding: 35px 25px 45px;
  }
}

.modalHeader {
  padding: 26px 0 15px;
}

.modalHeader h2 {
  font-size: 26px;
  font-weight: 600;
  margin: 0;
}

.countrySwitch {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.countrySwitchWell {
  border: 1px solid rgb(214, 214, 214);
  border-radius: 4px;
  overflow: hidden;
}

.countrySwitchHeading {
  background-color: rgb(246, 246, 246);
  padding: 16px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.countrySwitchHeading h3 {
  text-align: center;
  font-size: 14px
}

.countrySwitchDescription {
  padding: 35px 25px;
  color: var(--greyDark);
  font-size: 14px;
}

.countrySwitchHeading h1 {
  text-align: center;
}

.countrySwitchFlag {
  width: 40px;
  height: 40px;
}

.countrySwitchInputLabel {
  color: rgb(214, 214, 214);
  font-size: 14px;
  margin-bottom: 10px;
}

.countryButton {
  display: flex;
  align-items: center;
  text-decoration: underline;
  margin-bottom: 20px;
}

.countryButtonFlag {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.countrySwitchAlert {
  color: rgb(229, 27, 35);
  margin: 0;
  text-align: center;
}