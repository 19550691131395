
.container {
  font-size:var(--standardInputFontSize);
  position: relative;

  ::placeholder {
    opacity: var(--standardInputPlaceHolderOpacity);
    font-size: inherit;
  }
}

.tel,
.text,
.email,
.file,
.number,
.password,
.phoneFormat,
.select,
.textarea {
  background-color: var(--standardInputBackgroundColor);
  border-radius: var(--standardInputBorderRadius);
  border: var(--standardInputBorderStyle);
  height: var(--standardInputHeight);

  input,
  textarea,
  select {
    height: 100%;
    padding: var(--padding);
    width: 100%;
  }
}

.valid {
  border: 1px solid var(--successColor);
}

.invalid {
  border: 1px solid var(--errorColor);
}

.number.small,
.text.small {
  height: var(--smallInputHeight);
}

.tel,
.email,
.number,
.phoneFormat,
.password,
.text {
  --padding: 0 18px;
}

.textarea {
  --padding: 18px;
  height: 30rem;
}

.select {
  --padding: 0 10px;
}

.file {
  background-color: transparent;
  border: none;
  padding-top: 1rem;
}

.readOnly {
  background-color: var(--inputWrapperReadOnlyBackgroundColor, var(--greyOnLight));
  pointer-events: none;
  color: var(--inputWrapperReadOnlyColor, var(--greyMedium));
  border: var(--inputWrapperReadOnlyBorder, var(--standardInputBorderStyle));
}

.prefix,
.suffix {
  composes: flexCenterVertical from '~css/alignment.css';
  font-size: 1.6rem;
  height: 100%;
  position: absolute;
  top: 0;

  &.small {
    font-size: var(--bodySmall)
  }
}

.prefix {
  padding: 0 15px;

  &.small {
    padding: 0 10px;
  }
}

.suffix {
  padding: 0 20px;
}

.container[dir='ltr'] {
  &.file {
    padding-left: 0;
  }

  .prefix {
    left: 0;
  }
  .suffix {
    right: 0;
  }

  .prefix,
  .suffix {
    > *:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.container[dir='rtl'] {
  &.file {
    padding-right: 0;
  }

  .prefix {
    right: 0;
  }

  .suffix {
    left: 0;
  }

  .prefix,
  .suffix {
    > *:not(:last-child) {
      margin-left: 1rem;
    }
  }
}

:global {
  .accessibilityMode {
    :local {
      .hasFocus { /* stylelint-disable-line */
        @mixin focusVisible;
      }
    }
  }
}