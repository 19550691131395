.visibilityToggle {
  position: relative;
  transition: 0.3s;

  &::after {
    content: "";
    height: 1px;
    width: 0;
    position: absolute;
    transition: 0.3s;
    background-color: currentColor;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &.hideInput::after {
    width: 125%;
  }
}

.selectSuffix {
  pointer-events: none;
}