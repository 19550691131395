.pseudoArrow {
  --arrowColor: var(--bodyBackgroundColor);
  position: relative;

  &::before,
  &::after {
    border: solid transparent;
    content: '';
    height: 0;
    pointer-events: none;
    position: absolute;
    width: 0;
  }

  &::before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: var(--greyLight);
    border-width: 11px 18px;
  }

  &::after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: var(--arrowColor);
    border-width: 10px 15px;
  }
}

.pseudoArrowTop {
  composes: pseudoArrow;

  &::before,
  &::after {
    bottom: 100%;
  }

  &::after {
    bottom: calc(100% - 1px);
  }
}

.overlayCard {
  composes: aboveNormal from '~css/z-index.css';
  background-color: var(--bodyBackgroundColor);
  border-top: 1px solid var(--greyLight);
  box-shadow: 1px 2px 7px 1px rgba(153, 153, 153, 0.23);
  position: absolute;
}

@media (--tabletMedium) {
  .tabletMediumHide {
    display: none;
  }
}

.roundedSides {
  border-radius: 9999999px;
}

/* html[dir='rtl'] {
  .overlayCard {
    left: 0;
  }
} */
