.container {
  position: relative;
}

.button {
  composes: flexCenterVertical from '~css/alignment.css';
}

.besideLabel {
  composes: flexCenterVertical from '~css/alignment.css';
}

.headingWrapper {
  display: flex;
  align-items: baseline;
}

html[dir='ltr'] {
  .headingWrapper {
    > * {
      margin-right: 1rem;
    }
  }
}

html[dir='rtl'] {
  .headingWrapper {
    > * {
      margin-left: 1rem;
    }
  }
}

.heading {
  composes: lineHeightSmall from '~css/typography.css';
}

.xxl {
  composes: heading;
  composes: headingXXL from '~css/typography.css';
}

.xl {
  composes: heading;
  composes: headingXL from '~css/typography.css';
}

.large {
  composes: heading;
  composes: headingLarge from '~css/typography.css';
}

.medium {
  composes: heading;
  composes: headingMedium from '~css/typography.css';
}

.small {
  composes: heading;
  composes: headingSmall from '~css/typography.css';
}

.xs {
  composes: heading;
  composes: headingXS from '~css/typography.css';
}