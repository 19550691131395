@media(--aboveTabletMedium) {
  .columns {
    display: flex;
  }
}

.section {
  margin-bottom: 20px;
}

.left {
  justify-content: left;
}

.right {
  justify-content: right;
}

.center {
  justify-content: center;
}

.navContent {
  display: flex;
}

html[dir='ltr'] {
  .navContent {
    > :not(:last-child) {
      margin-right: 30px;
    }
  }

  @media(--aboveTabletMedium) {
    .columns .section {
      margin-right: 60px;
    }
  }
}

html[dir='rtl'] {
  .navContent {
    > :not(:last-child) {
      margin-left: 30px;
    }
  }

  @media(--aboveTabletMedium) {
    .columns .section {
      margin-left: 60px;
    }
  }
}