.grid {
  display: grid;

  > :nth-child(1) {
    -ms-grid-column: 1;
  }

  > :nth-child(2) {
    -ms-grid-column: 2;
  }

  > :nth-child(3) {
    -ms-grid-column: 3;
  }

  > :nth-child(4) {
    -ms-grid-column: 4;
  }

  > :nth-child(5) {
    -ms-grid-column: 5;
  }

  > :nth-child(6) {
    -ms-grid-column: 6;
  }

  > * {
    min-width: 0; /* https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items */
  }
}

.inlineGrid {
  display: inline-grid;
}
