$padding: 2rem;

.container {
  composes: flexCenterVertical from '~css/alignment.css';
  /* Matches paginator height. So content doesn't shift on no-results */
  min-height: 3.8rem;
  white-space: nowrap;
}

.dropdown {
  composes: pseudoArrowTop from '~css/utils.css';
  composes: aboveNormal from '~css/z-index.css';
  composes: overlayCard from '~css/utils.css';
  bottom: -1.5rem;
  transform: translateY(100%);
}

.viewport {
  max-height: 35vh;
  padding: 2rem;
}
.button {
  padding-left: 10px;
  padding-right: 10px;
}

html[dir='ltr'] {
  .button {
    margin-right: 2rem;
  }

  .dropdown {
    &:before,
    &:after {
      left: 9px;
    }

    &:after {
      left: 11px;
    }
  }
}

html[dir='rtl'] {
  .button {
    margin-left: 2rem;
  }

  .dropdown {
    &:before,
    &:after {
      right: 9px;
    }

    &:after {
      right: 11px;
    }
  }
}
