.button {
  composes: lineHeightOne from '~css/typography.css';
  background-color: var(--favoriteButtonBgColor);
  border-radius: 50%;
  display: block;
}

.default {
  padding: var(--favoriteButtonPadding);

  @media(--tabletMedium) {
    padding: 5px;
  }
}

.darker {
  background-color: var(--favoriteButtonDarkerBgColor);
  padding: 10px;
}
