$border: 1px solid var(--greyLight);

.dropdown {
  composes: flexCenter from '~css/alignment.css';
  height: 100%;
  position: relative;
}

.expandedWrapper {
  position: fixed;
  z-index: 1000;
}

.card {
  composes: aboveMenu from '~css/z-index.css';
  composes: overlayCard from '~css/utils.css';
  composes: pseudoArrowTop from '~css/utils.css';
  composes: lineHeightOne from '~css/typography.css';
  border: $border;

  &.dropdownOnLeft {
    left: 0;
  }

  &.dropdownOnRight::before {
    right: var(--arrowOffset);
  }

  &.dropdownOnRight:after {
    right: calc(var(--arrowOffset) + 1px);
  }

  &.dropdownOnLeft::before {
    left: var(--arrowOffset);
  }

  &.dropdownOnLeft:after {
    left: calc(var(--arrowOffset) + 1px);
  }
}


@media (--mobileLarge) {
  .expandedWrapper,
  .card {
    /* stylelint-disable-next-line declaration-no-important */
    width: 100vw !important; 
  }
}