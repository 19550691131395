$smallSquare: 3.5rem;
$largeSquare: 4rem;
$grey: var(--greyInactive);

.button {
  padding: 0 10px;
  width: 100%;

  /* Size Props */
  &.large {
    height: $largeSquare;
    min-width: $largeSquare;
  }

  &.small {
    height: $smallSquare;
    min-width: $smallSquare;
  }
}
.disabled {
  background:
    linear-gradient(
      to top right,
      transparent calc(50% - 1px),
      $grey,
      transparent calc(50% + 1px)
    );
  border: 1px solid $grey;
  color: $grey;
  cursor: not-allowed;
  opacity: 0.5;
}
