.container {
  composes: flexCenterVertical from '~css/alignment.css';
  padding: 5px;
}

.imageWrapper {
  width: 3rem;
}

html[dir='ltr'] {
  .iconWrapper,
  .imageWrapper {
    margin-right: 10px;
  }
}

html[dir='rtl'] {
  .iconWrapper,
  .imageWrapper {
    margin-left: 10px;
  }
}