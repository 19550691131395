.wrapper > * {
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  display: block;
  margin: 0 auto;
  padding: 0.5rem;
  border-radius: 100%;
  border: 0.4rem solid black;
}

.iconWrapper {
  margin: 3rem 0;
}

.header {
  margin-bottom: 2rem;
}

.details {
  composes: bodyXXS from '~css/typography.css';
  text-align: center;
  margin-bottom: 2rem;
}

.button {
  width: 100%;
  height: var(--standardInputHeight);
}