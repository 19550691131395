.category {
  margin-bottom: 2rem;
}

.buttonWrapper {
  margin-bottom: 0.5rem;

  @media (--tabletMedium) {
    margin-bottom: 15px;
  }
}

.showMore {
  composes: flexCenterVertical from '~css/alignment.css';
  composes: bodySmall lineHeightOne from '~css/typography.css';

  background-color: var(--greyLight);
  border-radius: 1rem;
  margin-top: 2rem;
  padding: 0.5rem 1rem;

  svg {
    margin-left: 0.5rem;
  }
}
