.imageButton {
  display: flex;
  align-items: center;
}

.imageButtonPrefix {
  margin-right: 5px;
}

.imageButtonText {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.imageButtonImage {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
