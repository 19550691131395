$width: 30px;
$height: calc($width / 2);
$extra: 2px;
$buttonSize: calc($height + $extra);

.slider {
  background-color: var(--greyMedium);
  cursor: pointer;
  height: 100%;
  position: relative;
  transition: 0.4s;

  &:before {
    background-color: black;
    content: "";
    height: $buttonSize;
    position: absolute;
    transition: .4s;
    width: $buttonSize;
    top: calc($extra - ($extra * 1.5));
    left: calc($extra - ($extra * 1.5));
  }
}

.switch {
  width: $width;
  height: $height;

  input {
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;

    &:checked + .slider {
      background-color: var(--greyLight);
    }

    &:checked + .slider:before {
      transform: translateX(calc($width - $height));
    }
  }
}

/* Rounded sliders */
.round {
  border-radius: $height;

  &:before {
    border-radius: 50%;
  }
}
