.imageWrapper {
  margin-left: auto;
  margin-right: auto;
}

.imageLink {
  display: block;
  width: 100%;
}

/* Caption Positioning */

.textOverlay {
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  .absolute {
    position: absolute;
  }

  &.center {
    justify-content: center;
  }
}

.caption {
  height: 100%;
  position: relative;
}

.xLeft {
  left: 0;
}

.xRight {
  margin-left: auto;
  right: 0;
}

.xCenter {
  margin-left: auto;
  margin-right: auto;
}

.yTop {
  top: 0;
}

.yBottom {
  bottom: 0;
  margin-top: auto;
}

.captionHorizontal {
  align-items: center;
  display: flex;

  > * {
    flex: 1;
    min-width: 0;
  }
}

.buttonsWrapper {
  width: 100%;
}
