.container {
  position: relative;
}

.absolute {
  composes: aboveAlways from '~css/z-index.css';
  position: absolute;
  min-width: 100%;
  top: calc(100% + 1px);
}


html[dir='ltr'] {
  .absolute {
    right: 0;
  }
}

html[dir='rtl'] {
  .absolute {
    left: 0;
  }
}
