.itemWrapper {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.imageWrapper {
  width: 3.5rem;
}

html[dir='ltr'] {
  .iconWrapper,
  .imageWrapper {
    margin-right: 10px;
  }
}

html[dir='rtl'] {
  .iconWrapper,
  .imageWrapper {
    margin-left: 10px;
  }
}
