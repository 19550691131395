.controlTrack {
  composes: gridFallbackFour from '~css/layout.css';
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  margin-top: 10px;
}

.wrapper {
  position: relative;
}

.start {
  margin-top: 0;
}

.iconWrapper {
  composes: absoluteCenter from '~css/alignment.css';
  background-color: rgba(255, 255, 255, 1);
  border-radius: 50%;
  color: var(--greyLight);
  padding: 0.8rem;
  pointer-events: none;

  svg {
    position: relative;
  }
}

@media (--aboveTabletMedium) {
  .start {
    display: block;
  }
}

.control {
  border: 1px solid transparent;
  padding: 3px;
  width: 100%;

  &.active {
    border-color: var(--colorActivePrimary);
  }
}

@media (--tabletMedium) {
  .controlTrack {
    grid-gap: 5px;
    margin-left: 10px;
    margin-right: 10px;
    order: 1;
  }
}

html[dir='ltr'] {
  .iconWrapper {
    svg {
      left: 1.5px;
    }
  }
}

html[dir='rtl'] {
  .iconWrapper {
    svg {
      right: 1.5px;
    }
  }
}