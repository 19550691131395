.container {
  composes: flexCenter from '~css/alignment.css';
  position: relative;
}

.cartCount {
  composes: flexCenter from '~css/alignment.css';
}

.right {
  position: relative;
}

.center {
  composes: absoluteCenter from '~css/alignment.css';
  color: var(--headerIconColor);
}

.onTop {
  composes: flexCenter from '~css/alignment.css';
  border-radius: 12px;
  position: absolute;
}
