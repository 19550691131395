.containerMore, .containerLess {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

/* Grid used for lily and bean */
.grid2.containerLess > .image {
  width: 100%;
}

.grid2.containerMore > .image {
  width: 100%;
}
  
.grid2.containerMore > .image:nth-child(2) {
  width: calc(50% - 5px);
}

.grid2.containerMore > .image:nth-child(3) {
  width: calc(50% - 5px);
}

/* Grid used for american eagle */

/* For one to two images stack them on top of each other */
.grid1.containerLess > .image {
  width: 100%;
}

.grid1.containerMore > .image {
  order: 4;
  width: calc(50% - 5px);
}
  
.grid1.containerMore > .image:nth-child(1) {
  order: 3;
  width: 100%;
}
.grid1.containerMore > .image:nth-child(2) {
  order: 1;
} 
.grid1.containerMore > .image:nth-child(3) {
  order: 2;
}
