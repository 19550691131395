.container {
  min-height: 27px;
}

.tab {
  display: inline-block;
  text-align: center;
  font-size: var(--tab-font-size);
}

.activeTab {
  border-bottom: 1px solid black;
}

html[dir='ltr'] {
  .tab {
    &:not(:last-of-type) {
      margin-right: var(--tab-gap);
    }
  }
}

html[dir='rtl'] {
  .tab {
    &:not(:last-of-type) {
      margin-left: var(--tab-gap);
    }
  }
}

/* Props position */
.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}
