$dotSizeNormal: 11px;
$buttonSizeNormal: 20px;

.wrapper {
  /* Slick automatically inlines this div to be display: inline-block, which adds the phantom spacing and gives us less control over how we want the spacing to be */
  /* stylelint-disable-next-line declaration-no-important */
  display: block !important;

  &:focus,
  div:focus,
  img:focus {
    outline: none;
  }
}

/* stylelint-disable selector-max-compound-selectors */

.slickSlider {
  position: relative;
  user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  &.borderFix:after {
    background-color: var(--bodyBackgroundColor);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 1px;

    @media(--tabletMedium) {
      display: none;
    }
  }

  :global {
    .slick-loading .slick-slide,
    .slick-loading .slick-track {
      visibility: hidden;
    }

    .slick-list {
      overflow: hidden;
    }

    .slick-list:focus {
      outline: 0;
    }

    .slick-list.dragging {
      cursor: hand;
    }

    .slick-slider .slick-list,
    .slick-slider .slick-track {
      transform: translate3d(0, 0, 0);
    }

    .slick-track {
      display: flex;
      top: 0;
    }

    .slick-active {
      z-index: 1;
    }

    .slick-slide {
      position: relative;
    }

    .slick-disabled {
      cursor: default;
      opacity: 0.25;
    }

    .slick-dots {
      margin: 0;
      padding: 0;
      position: absolute;
      bottom: 25px;
      left: 50%;
      transform: translateX(-50%);
    }

    .slick-dots li {
      display: inline-block;
      margin: 0;

    }

    .slick-dots li:not(.slick-active) {
      opacity: 1;
    }

    .slick-dots li.slick-active button::after {
      opacity: 1;
      background-color: white;
    }

    .slick-dots button {
      background-color: transparent;
      border: 0;
      font-size: 0;
      height: $buttonSizeNormal;
      outline: 0;
      padding: 0;
      position: relative;
      width: $buttonSizeNormal;
    }

    .slick-dots button::after {
      background-color: transparent;
      border-radius: 50%;
      border: 2px solid white;
      content: '';
      height: $dotSizeNormal;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: $dotSizeNormal;
    }
  }
}

html[dir='ltr'] {
  .slickSlider {
    :global {
      /* stylelint-disable-next-line max-nesting-depth */
      .slick-track {
        left: 0;
      }
    }
  }
}

html[dir='rtl'] {
  .slickSlider {
    :global {
      /* stylelint-disable-next-line max-nesting-depth */
      .slick-track {
        right: 0;
      }
    }
  }
}
