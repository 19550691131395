.container {
  position: relative;

  video {
    max-width: 100%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 993px){
  .fullContainer {
    width: 100%;
    height: unset;
    aspect-ratio: 9 / 16;
  }

}

@media only screen and (min-width: 993px){
  .fullContainer {
    width: 100%;
    height: unset;
    aspect-ratio: 16 / 9;
  }
}

.buffering {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.loader {
  composes: absoluteCenter from '~css/alignment.css';
}

.iframeVideo {
  top: 0;
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.iframeVideoContainer {
  position: relative;
}

.iframeVideoControls {
  z-index: 20;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

.iframVideoControl {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  color: black;
  background-color: white;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
