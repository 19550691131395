.dropdown {
  min-width: 500px;
  position: relative;

  .viewport {
    padding-bottom: 4rem;
    max-height: 35vh;
    overflow: auto;
  }

  .gradient {
    background: var(--fadingGradient);
    bottom: 0;
    height: 6rem;
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
  }

  .category {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .buttonWrapper {
    min-width: 180px;
  }
}

.category {
  margin-bottom: 2rem;
}

.columns {
  column-gap: 40px;
  column-rule: 1px solid var(--greyLight);
}

.columns2 {
  column-count: 2;
}

.columns3 {
  column-count: 3;
}
