.input {
  border: 1px solid;
  border-radius: var(--standardInputBorderRadius);
  cursor: pointer;
  font-weight: 500;
  height: var(--standardInputHeight);
  line-height: 100%;
  padding: 0 3rem 0 1.5rem;
  position: relative;
  text-align: inherit;
  width: 100%;

  &:focus,
  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
}
