.horizontal {
  display: inline-flex;
}

.vertical {
  > * {
    display: block;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}


html[dir='ltr'] {
  .horizontal {
    > *:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

html[dir='rtl'] {
  .horizontal {
    > *:not(:last-child) {
      margin-left: 2rem;
    }
  }
}