.container {
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
    pointer-events: none;
    width: 100%;
  }
}

.center {
  composes: absoluteCenter from '~css/alignment.css';
}

.svgWrapper {
  transition: transform 0.3s ease;
}

.grow {
  transform: scale(1.3);
}

.pulse {
  :global { /* stylelint-disable-line selector-pseudo-class-no-unknown */
    animation: pulse 2s infinite;
  }
}

@keyframes :global(pulse) {
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}


@keyframes :global(pulse) {
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(1.1)
  }
  100% {
    transform: scale(1)
  }
}
