.container {
  max-width: calc(100% - (var(--tabletPadding) * 2));
}

.wrapper {
  composes: flexCenter from '~css/alignment.css';
  height: 65vh;
  text-align: center;
}

.loaderWrapper {
  composes: flexCenter from '~css/alignment.css';
  height: 10vh;
  margin-top: -10vh;
}