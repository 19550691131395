.overlay {
  composes: aboveNormal from '~css/z-index.css';
  background-color: var(--backgroundOverlay);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}

.tooltip {
  composes: overlay;
  composes: aboveMenu from '~css/z-index.css';
  display: none;
}

.pullout {
  composes: aboveAlways from '~css/z-index.css';
  cursor: pointer;
}

@media(--tabletMedium) {
  .tooltip {
    display: block;
  }

  .mobile {
    composes: overlay;
    background-color: rgba(0, 0, 0, 0.8);
  }
}
