.selectableMenuWrapper {
  position: relative;
  margin-bottom: 1rem;
  max-width: 300px;
}

.selectableMenuButton {
  display: inline-flex;
  text-align: center;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  border: 1px solid black;
  font-size: 1.2rem;
  color: var(--colorActivePrimary);

  &:disabled {
    color: var(--greyInactive);
    cursor: not-allowed;
  }
}

[data-is-open="true"] .selectableMenuIcon {
  transform: translateY(-50%) rotate(180deg);
}

.selectableMenuIcon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.selectableMenu {
  margin: 0;
  padding: 0;
  position: absolute;
  overflow: hidden;
  top: 100%;
  width: 100%;
  background-color: var(--bodyBackgroundColor);
  composes: aboveNormal from '~css/z-index.css';
  border-radius: 0 0 5px 5px;
}

menu.selectableMenu button.selectableMenuButton.selectableMenuItemButton {
  border-top: 0;
}

menu.selectableMenu button.selectableMenuButton.selectableMenuItemButton:disabled {
  border-left: 1px solid black;
  border-right: 1px solid black;
  pointer-events: none;
}


menu.selectableMenu button.selectableMenuButton:not(:last-child) {
  border-bottom: 0;
}

.selectableTriggerButton {
  border-radius: 5px;
  padding: 1rem 3rem 1rem 1.5rem;
}

.selectableMenuWrapper .selectableTriggerButton.selectableMenuButtonOpen {
  border-bottom: 0;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
}

.selectableMenuButtonOpen + .selectableMenu .selectableMenuButton:last-child {
  border-radius: 0 0 5px 5px;
  overflow: hidden;
}

.selectableScreenReader {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
}

