.categoryCard {
  border: 1px solid var(--greyLight);
  padding: 15px;
}

.imageWrapper {
  display: block;
  margin-bottom: 10px;
}

.item {
  padding: 8px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--greyLight);
  }

  a {
    display: flex;
    align-items: center;
  }

  svg {
    margin-left: auto;
  }
}
