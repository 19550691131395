$backButton: 20px;
$spacing: 5px;

.breadcrumbs {
  composes: flexCenterVertical from '~css/alignment.css';
  composes: lineHeightOne bodyXS from '~css/typography.css';
  color: var(--greyInactive);
  text-transform: var(--variableCasing);
  flex-wrap: wrap;
  row-gap: 8px;
}

.item {
  a {
    display: inline-block;
  }
}

.back {
  composes: flexCenterVertical from '~css/alignment.css';
  color: black;
  font-size: var(--linkXs);
}

.backIcon {
  composes: flexCenter from '~css/alignment.css';
  background-color: black;
  border-radius: 50%;
  color: white;
  height: $backButton;
  width: $backButton;
}

html[dir='ltr'] {
  .back {
    border-right: 1px solid black;
    margin-right: calc($spacing * 2);
    padding-right: calc($spacing * 2);
  }

  .backIcon {
    margin-right: calc($spacing * 2);
  }

  .item {
    a {
      margin-right: $spacing;
    }

    &:not(:last-child)::after {
      content: "/";
      margin-right: $spacing;
    }
  }
}

html[dir='rtl'] {
  .back {
    border-left: 1px solid black;
    margin-left: calc($spacing * 2);
    padding-left: calc($spacing * 2);
  }

  .backIcon {
    margin-left: calc($spacing * 2);
  }

  .item {
    a {
      margin-left: $spacing;
    }

    &:not(:last-child)::after {
      content: "\\";
      margin-left: $spacing;
    }
  }
}