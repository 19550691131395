.lineHeightNormal {
  height: calc(1em * 1.6); /* stylelint-disable-line unit-disallowed-list */
}

.lineHeightSmall {
  height: calc(1em * 1.3); /* stylelint-disable-line unit-disallowed-list */
}

/* Heading */

.headingXL {
  composes: headingXL from '~css/typography.css';
}

.headingLarge {
  composes: headingLarge from '~css/typography.css';
}

.headingMedium {
  composes: headingMedium from '~css/typography.css';
}

.headingSmall {
  composes: headingSmall from '~css/typography.css';
}

.headingXS {
  composes: headingXS from '~css/typography.css';
}


/* Subline */

.sublineXL {
  composes: sublineXL from '~css/typography.css';
}

.sublineLarge {
  composes: sublineLarge from '~css/typography.css';
}

.sublineMedium {
  composes: sublineMedium from '~css/typography.css';
}

.sublineSmall {
  composes: sublineSmall from '~css/typography.css';
}


/* BODY */

.bodyLarge {
  composes: bodyLarge from '~css/typography.css';
}

.bodyRegular {
  composes: bodyRegular from '~css/typography.css';
}

.bodySmall {
  composes: bodySmall from '~css/typography.css';
}

.bodyXS {
  composes: bodyXS from '~css/typography.css';
}

.bodyXXS {
  composes: bodyXXS from '~css/typography.css';
}
