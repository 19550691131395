.item {
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  display: block;
  width: 100%;

  &:not(:last-of-type) {
    border-bottom: 1px solid;
  }
}
 
.disabled {
  background-color: var(--greyInactive);
  cursor: not-allowed;
}

.highlighted {
  outline: -webkit-focus-ring-color auto 1px;
}

.selected {
  background-color: var(--colorActivePrimary);
  color: var(--bodyBackgroundColor);
}

