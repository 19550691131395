.svg {
  fill: currentColor;

  path {
    fill: inherit;
  }
}

.block {
  display: block;
}
