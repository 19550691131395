.labelContainer {
  composes: flexCenterVertical from '~css/alignment.css';
  margin-bottom: 0.5rem;
}

.label {
  composes: bodySmall from '~css/typography.css';
  display: block;
  font-weight: 700;

  &.small {
    font-size: var(--bodySmall)
  }
}
