.subline {
  composes: lineHeightSmall from '~css/typography.css';
}

.xxl {
  composes: subline;
  composes: sublineXXL from '~css/typography.css';
}

.xl {
  composes: subline;
  composes: sublineXL from '~css/typography.css';
}

.large {
  composes: subline;
  composes: sublineLarge from '~css/typography.css';
}

.medium {
  composes: subline;
  composes: sublineMedium from '~css/typography.css';
}

.small {
  composes: subline;
  composes: sublineSmall from '~css/typography.css';
}

.xs {
  composes: subline;
  composes: sublineXS from '~css/typography.css';
}
