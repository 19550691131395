.checkboxLabel {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: var(--headingXs);
  padding: 0 1rem 0 0;
  min-height: 4rem;
  min-width: 155px;
  max-width: 100%;

  & > span {
    align-items:center;
    display:flex;
    margin-right: 10px;
  }

  & svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.input {
  display: none;

  &:checked + span span {
    background-color:  var(--dynamicPrimaryColor);
    border-radius: var(--standardInputBorderRadius);
    color: var(--dynamicTertiaryColor);
    display: inline-block;
    opacity: 1;
    position:relative;
    overflow: hidden;    
  } 

  &:disabled ~ span {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.checkbox {
  border: 1px solid var(--greyInactive);
  border-radius: var(--standardInputBorderRadius);
  box-sizing: border-box;
  display: inline-block;
  height: var(--checkboxSize);
  width: var(--checkboxSize);
}
