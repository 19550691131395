.navArrow {
  position: absolute;
  z-index: 1;
}

html[dir='ltr'] {
  .start.overlay,
  .start.outlay {
    left: calc(0px + var(--arrowOffsetX) + var(--halvedGapWithUnit));
  }

  .end.outlay,
  .end.overlay {
    right: calc(0px + var(--arrowOffsetX) + var(--halvedGapWithUnit));
  }
}

html[dir='rtl'] {
  .start.overlay,
  .start.outlay {
    right: calc(0px + var(--arrowOffsetX) + var(--halvedGapWithUnit));
  }

  .end.outlay,
  .end.overlay {
    left: calc(0px + var(--arrowOffsetX) + var(--halvedGapWithUnit));
  }
}

/* radius props */
.borderRadiusRounded {
  border-radius: 50%;
}

/* component margins */

.marginXs {
  margin: 5px;
}

.marginSmall {
  margin: 10px;
}

.marginMedium {
  margin: 20px;
}

.marginLarge {
  margin: 30px;
}

/* Component Paddings */
.paddingXs {
  padding: 5px;
}

.paddingSmall {
  padding: 10px;
}

.paddingMedium {
  padding: 20px;
}

.paddingLarge {
  left: 30px;
}
