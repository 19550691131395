$border: 1px solid var(--greyLight);

.item {
  display: flex;
  padding: 20px;

  &:not(:last-of-type) {
    border-bottom: $border;
  }
}

.remove {
  composes: bodyXS from '~css/typography.css';
  margin-top: 10px;
  text-decoration: underline;
  text-transform: uppercase;
}

.image {
  flex: 0 0 80px;
  margin-right: 10px;
  width: 80px;
  
  a {
    display: block;
  }
}

.title {
  composes: bodySmall from '~css/typography.css';
  font-weight: 500;
}

.aboutItem {
  padding: 10px 0;
}

html[dir='ltr'] {
  .image {
    margin-right: 10px;
  }
}

html[dir='rtl'] {
  .image {
    margin-left: 10px;
  }
}
