$buttonSize: 30px;

.paginator {
  composes: flexCenter from '~css/alignment.css';

  /* Magic number to match the height of a selected button so content doesn't shift when removing all selected buttons */
  /* 1.2rem font size + (8px * 2) padding + (5px * 2) margin */
  min-height: 3.8rem;

  span {
    margin: 0 1rem;
    white-space: nowrap;
  }
}

.button {
  composes: flexCenter from '~css/alignment.css';
  background-color: var(--paginatorButtonBg);
  border-radius: 50%;
  border: var(--paginatorButtonBorder);
  color: var(--paginatorButtonArrowColor);
  height: $buttonSize;
  width: $buttonSize;

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
}

/* Size prop */

.small {
  composes: bodySmall from '~css/typography.css';
}
