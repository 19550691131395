$titleHeight: calc(var(--productCardTitleFontSize) * 1.6); /* MATCH NATURAL LINK HEIGHT */
$calloutWidth: 30px;
$moveIn: 10px;

.productCardBrandName {
  font-weight: var(--productCardBrandWeight);
}

.cardImageWrapper {
  position: relative;
}

.promotionWrapper {
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.badgeWrapper {
  composes: promotionWrapper;
  display: flex;
  padding: 10px;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  
  @media (--tabletMedium) {
    padding: 7px;
  }
}

.bannerWrapper {
  composes: promotionWrapper;
  bottom: -1px;
  text-align: center;
}

.textWrapper {
  composes: bodyXS from '~css/typography.css';
  margin-top: 5px;
}

.ranking {
  composes: flexCenter from '~css/alignment.css';
  composes: bodyXS lineHeightOne from '~css/typography.css';
  background-color: var(--rankingBackgroundColor);
  border-radius: 50%;
  color: var(--rankingColor);
  height: $calloutWidth;
  left: $moveIn;
  position: absolute;
  top: $moveIn;
  width: $calloutWidth;
}

.oosBadge {
  composes: absoluteCenter from '~css/alignment.css';
  z-index: 1;
}

.lowStockMessage {
  composes: bodySmall from '~css/typography.css';
  color: var(--lowStockColor);
}

/** RANKING SIZE PROPS **/

.xs {
  composes: bodyXS from '~css/typography.css';
}

.large {
  composes: bodyLarge from '~css/typography.css';
}

/****/

.favoriteWrapper {
  border-radius: 50%;
  top: var(--favoriteWrapperPosition);
  position: absolute;
  right: var(--favoriteWrapperPosition);
  transition: box-shadow 0.3s ease;
  z-index: 1;

  &:hover {
    box-shadow: 0 0 5px rgba(0,0,0,0.45);
  }

  @media (--tabletMedium) {
    top: $moveIn;
    right: $moveIn;
  }
}

.title {
  display: block;
  font-family: var(--productCardTitleFontFamily);
  font-weight: var(--productCardTitleWeight);
  font-size: var(--productCardTitleFontSize);
  height: $titleHeight;
  max-width: 100%;
  overflow: hidden;
}

/* HACK */

.ellipsisWrapper {
  color: var(--productCardTitleColor);
  max-height: calc($titleHeight * 2);
  position: relative;
  word-break: break-all;

  &::after {
    background:
      linear-gradient(
        270deg,
        var(--bodyBackgroundColor) 75%,
        rgba(255,255,255,0) 100%
      );
    bottom: $titleHeight;
    content: '...';
    height: $titleHeight;
    padding-left: 6.5px;
    position: absolute;
    right: 0;
  }
}

.priceWrapper {
  margin-bottom: var(--spacingSmall);
}
