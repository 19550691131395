.section {
  border-top: 1px solid rgb(238,238,238);
  padding: 30px 0;
  display: flex;
}

.section.vertical {
  flex-direction: column;
}

.section.vertical .sectionLabel {
  flex-direction: column;
  flex-basis: auto;
}

.sectionLabel {
  font-weight: 600;
  flex-basis: 78px;
  flex-shrink: 0;
  font-size: 11px;
}

.sectionContent {
  display: flex;
  flex-shrink: 1;
  flex-basis: 100%;
  width: 100%;
}

.sectionContent.right {
  display: flex;
  justify-content: flex-end;
}
