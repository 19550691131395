.chipLink {
  border: 1px solid var(--greyLight);
  border-radius: var(--standardInputBorderRadius);
  cursor: pointer;
  font-size: inherit;
  font-weight: 500;
  min-width: max-content;
  padding: 0.75rem 1.5rem;
  transition-duration: var(--default-transition-duration);
  transition-property: background-color,border-color,color;
  white-space: nowrap;
  width: auto;

  &[disabled="true"] {
    background: linear-gradient(to top right,var(--greyLight) calc(50% - 1px),var(--greyInactive),var(--greyLight) calc(50% + 1px));
    border-color: var(--greyLight);
    color: var(--bodyBackgroundColor);
    cursor: not-allowed;
  }

  &:hover:not([disabled]),
  &:focus {
    border-color: var(--colorActivePrimary);
  }
}
