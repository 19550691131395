.layout {
  min-width: 0; /* pdp-slick-carousel needs this */
}

.justifyCenter {
  display: flex;
  justify-content: center;
}

.justifyRight {
  display: flex;
  justify-content: flex-end;
}
