.flex {
  display: flex;

  &.horizontal {
    > * {
      min-width: 0;
    }
  }

  &.vertical {
    flex-direction: column;
    height: 100%;

    > *:not(:last-child) {
      margin-bottom: var(--gap);
    }
  }
}

html[dir='ltr'] {
  .flex {
    &.horizontal > *:not(:last-child) {
      margin-right: var(--gap);
    }
  }
}

html[dir='rtl'] {
  .flex {
    &.horizontal > *:not(:last-child) {
      margin-left: var(--gap);
    }
  }
}

.wrap {
  flex-wrap: wrap;
}