.beneathNormal {
  z-index: -1;
}

.productLoading {
  z-index: 9;
}

.aboveNormal {
  z-index: 10;
}

.menu {
  z-index: 99;
}

.aboveMenu {
  z-index: 100;
}

.aboveAlways {
  z-index: 1000;
}

.aboveAll {
  z-index: 10000;
}
