.tabContainer {
  margin-bottom: 2rem;
}

.buttonWrapper {
  display: flex;

  > * {
    flex: 1;
  }
}