.link {
  display: block;
}

.logoContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: var(--logoRatio);
    width: 100%;
  }

  svg {
    position: absolute;
    top: 0;
  }
}

html[dir='ltr'] {
  .logoContainer {
    svg {
      left: 0;
    }
  }
}

html[dir='rtl'] {
  .logoContainer {
    svg {
      right: 0;
    }
  }
}