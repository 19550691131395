.circle {
  border-radius: 50%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.large {
  height: var(--buttonLarge);
}

.full {
  composes: large;
  width: 100%;
}
