.itemDetails {
  display: flex;
}

.aboutItem {
  padding: 10px 0;
}

.detail {
  composes: bodyXS from '~css/typography.css';
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 3px;
  }
}

.label,
.value {
  text-transform: uppercase;
}

.label {
  color: var(--greyDark);
}

.discounts {
  margin-top: 10px;
}

.discount {
  composes: bodySmall from '~css/typography.css';
  color: var(--errorColor);
  margin-bottom: 5px;
}

html[dir='ltr'] {
  .label,
  .value {
    margin-right: 2px;
  }
}

html[dir='rtl'] {
  .label,
  .value {
    margin-left: 2px;
  }
}
