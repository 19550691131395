.layout {
  position: relative;
}

.anchor {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 320px;
}

.container {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 350px;
  background-color: var(--bodyBackgroundColor);
  border: 1px solid rgb(214,214,214);
  padding: 28px 34px 20px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.countries {
  display: flex;
  justify-content: center;
  align-items: top;
  margin-top: 30px;
  margin-bottom: 20px;
  gap: 15px;
}

.country {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
}

.countryImage {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.countryName {
  font-size: 12px;
  text-align: center;
  word-break: break-word;
}

.actions {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  text-decoration: underline;
  font-size: 12px;
}

.actionDivider {
  display: inline-block;
  margin: 0 10px;
}

.button {
  font-weight: 700;
}

.disclaimer {
  margin-top: 15px;
  color: rgb(102, 102, 102);
  font-size: 10px;
  letter-spacing: .5px;
  vertical-align: middle;
}



