.sizeGuideModal {
  width: 1020px;
  max-width: 100%;
  padding: 24px 32px;
  min-height: calc(100vh - 62px); /* the 60px came from $gap in Modal.css. And -2px to remove the vertical scroll bar because of modal borders */
}

@media (--tabletMedium) {
  .sizeGuideModal {
    min-height: calc(100vh - 2px); /* -2px to remove the vertical scroll bar because of modal borders */
  }
}

.sizeGuideIcon {
  border-radius: 50%;
  padding: 0.25rem;
}

.sizeGuideIcon svg {
  margin-bottom: -1.25rem;
}

.sizeGuideButton {
  composes: bodySmall from '~css/typography.css';
}

.tabsContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.tabsContainer button {
  margin: 0 15px;
  padding: 10px 0;
}

.tabsButtonActive {
  border-bottom: 1.5px solid rgb(33, 33, 33);
}

.measureContentContainer {
  display: flex;
  width: 100%;
  gap: 10px;
}

.measureContentContainer  > div {
  flex: 1;
}

@media (--tabletMedium) {
  .measureContentContainer {
    flex-direction: column;
  }

  .measureContentContainer  > div {
    flex: 0;
  }
}

.diagramimageContainer {
  display: flex;
  justify-content: center;
}

.diagramimageContainer img {
  max-height: 65vh;
  object-fit: contain
}

.tableContentControls {
  display: flex;
  justify-content: space-between;
}

/* To give width to the dropdown category menu */
.tableContentControls > div:first-of-type {
  flex: 1;
  margin-right: 20px;
}

@media (--tabletMedium) {
  .tableContentControls {
    flex-direction: column;
    gap: 5px;
    margin-bottom: 15px;
  }
}

.multipleTablesContainer {
  /* table:not(:first-of-type) {
    margin-top: 20px;
  } */
}

.sizeGuideTable {
  width: 100%;
  border: solid 1px rgb(230, 230, 230);
  border-collapse: collapse;
  table-layout: fixed; /* This makes the column have equal widths */

  tbody {
    tr:hover {
      background-color: rgb(240, 240, 240);
    }
  }

  th {
    font-weight: 700;
    border: 1px solid rgb(230, 230, 230);
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  td:first-of-type {
    font-weight: 700;
    border-right: 1px solid rgb(230, 230, 230);
  }

  td {
    font-size: 14px;
    letter-spacing: 0.8px;
  }

  td, th {
    text-align: center;
    height: 48px;
  }
}

.descriptionList li {
  margin-top: 10px;
}

@media(--tabletSmall) {
  .sizeGuideTable th {
    font-size: 10px;
    letter-spacing: 0.5px;
  }

  .sizeGuideTable td {
    font-size: 12px;
  }
}
