.icon {
  align-items: center;
  display: flex;
  height: 1rem;
  position: absolute;
  right: 1rem;
  bottom: calc((var(--standardInputHeight) / 2) - 0.5rem);
  z-index: -1;

  svg {
    transition: transform 150ms;
  }
}

.flip {
  svg {
    transform: rotate(180deg);
    transform-origin: center;
  }
}
