.container {
  --indent: 1rem;
  composes: aboveAlways from '~css/z-index.css';
  position: fixed;
  top: var(--indent);
  left: var(--indent);
}

.skipLink {
  composes: aboveAlways from '~css/z-index.css';
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);

  &:focus-visible {
    width: auto;
    height: auto;
    clip: auto;
    background-color: white;
    padding: 10px;
    border-radius: 15px;
  }
}
