.container {
  background-color: var(--contactFormBackgroundColor);
  padding: var(--contactFormPadding);
  max-width: 600px;
  width: 100%;

  @media (--tabletMedium) {
    margin-left: auto;
    margin-right: auto;

    button {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.failure {
  color: var(--errorColor);
}
