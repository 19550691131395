.inputRange {
  padding-top: 1rem;
  text-align: center;
}

.inputRangeHolder {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
 
  .inputSeparator {
    display: flex;
    flex-direction: column;
    font-size: var(--bodySmall);
    justify-content: flex-end;
    line-height: 1.8rem; /* stylelint-disable-line property-disallowed-list */
    padding: .7rem;
  }
}

.inputRangeError {
  color: var(--errorColor);
  font-size: var(--bodySmall);
  margin-top: 1rem;
  text-align: left;
}
