.button {
  align-items: center;
  background-color: var(--greyLight);
  composes: lineHeightOne bodyXS from '~css/typography.css';
  display: inline-flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 8px;
  white-space: nowrap;
}

html[dir='ltr'] {
  .button {
    margin-right: 10px;

    svg {
      margin-left: 10px;
    }
  }
}

html[dir='rtl'] {
  .button {
    margin-left: 10px;

    svg {
      margin-right: 10px;
    }
  }
}
