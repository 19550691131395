/* stylelint-disable declaration-no-important */
.container {
  position: relative;
  padding-bottom: var(--aspect-ratio);
  overflow: hidden;
  
  > * {
    position: absolute !important;
    height: 100% !important;
    width: 100% !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  img,
  video {
    object-fit: var(--objectFit);
    object-position: center;
  }

  &:after{
    content: "";
    width: 9999px;
    display: block;
  }
}