.searchForm {
  --padding: 1rem;
  composes: flexCenterVertical from '~css/alignment.css';
}

.holder {
  composes: flexCenterVertical from '~css/alignment.css';
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.absolute {
  composes: absoluteVertical from '~css/alignment.css';
}

.inputWrapper {
  overflow: hidden;
  transition: 0.3s;
  flex: 1;
}

.input {
  height: 100%;
  padding: var(--padding);
  width: 100%;

  &.focussed {
    padding-left: var(--padding);
    padding-right: var(--padding);
    outline: none;
  }
}

.insideIcon {
  composes: absoluteVertical from '~css/alignment.css';
}

.iconWrapper {
  composes: flexCenter from '~css/alignment.css';
}


html[dir='ltr'] {
  .inputWrapper {
    right: 0;
  }

  .search {
    left: var(--startOffset);
    right: var(--endOffset);
  }

  .close {
    right: var(--closeOffset);
  }

  .input {
    padding-right: var(--paddingEnd);
    padding-left: var(--paddingStart);
  }
}

html[dir='rtl'] {
  .inputWrapper {
    left: 0;
  }

  .search {
    right: var(--startOffset);
    left: var(--endOffset);
  }

  .close {
    left: var(--closeOffset);
  }

  .input {
    padding-left: var(--paddingEnd);
    padding-right: var(--paddingStart);
  }
}
