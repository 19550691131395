$light: rgba(0, 0, 0, 0.03);
$medium: rgba(0, 0, 0, 0.2);
$dark: rgba(0, 0, 0, 0.5);

.imageDarker {
  position: relative;

  &::before {
    background-color: rgba(0, 0, 0, var(--imageOverlayOpacity));
    content: '';
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: background-color 0.5s ease;
    width: 100%;
    z-index: 1;
  }
}

.imageLink {
  composes: imageDarker;
  display: block;

  &:hover {
    &::before {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

.fade {
  composes: imageDarker;

  &::before {
    top: auto;
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(0,0,0,0.4),
      rgba(0,0,0,0) 50%
    );
  }
}

.overlay {
  composes: imageDarker;
}

.interactive {
  composes: imageLink;

  /* TODO: ADD DIFFERENT OPACITIES **/
  &.overlay {
    &:hover::before {
      background-color: $medium;
    }
  }
}

.hoverImage {
  visibility: none;
  opacity: 0;
  transition: opacity 0.5s ease;

  &:hover {
    visibility: visible;
    opacity: 1;
  }
}

@media (hover: none) {
  .hoverImage {
    display: none;
  }
}
