.wrapper {
  position: relative;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image {
  opacity: 1;
  transition: opacity 200ms;
}

.imageLoading {
  opacity: 0;
}
