html {
  /* 1 REM = 10px */
  font-size: 62.5%;
  text-size-adjust: none;

  @media (--tabletSmall) {
    /* 1 REM = 9px */
    font-size: 56.25%;
  }

  @media (--mobileLarge) {
    /* 1 REM = 8.5px */
    font-size: 53.125%;
  }
}

body {
  background-color: var(--bodyBackgroundColor);
  color: var(--bodyColor);
  font-family: var(--fontSecondary);
  font-size: 1.6rem;
  letter-spacing: var(--bodyLetterSpacing);
  line-height: 1.6; /* stylelint-disable-line property-disallowed-list */
  margin: 0;
  overflow-x: hidden;
}

:global {
  .vis-hidden {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .css3Loader {
    margin: auto;

    svg {
      width: 100%;
      height: 100%;
      overflow: visible;
      animation: rotate 1455ms infinite linear;
    }

    circle {
      stroke: currentColor;
      stroke-dasharray: 44px;
      stroke-dashoffset: 11px;
      transform-origin: center;
      transform: rotatey(180deg) rotate(90deg);
      animation: dash 3850ms infinite ease;
    }
  }

  .error-shake-short {
    animation-name: element-shake;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }

  .error-shake-long {
    animation-name: element-shake;
    animation-delay: 0.5s; /* add a delay to account for scrollIntoView duration */
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
  }
}

@keyframes :global(rotate) {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes :global(dash) {
  0% {
    stroke-dashoffset: 33px;
    transform: rotatey(0deg) rotate(0deg);
  }
  24.9999% {
    stroke-dashoffset: 11px;
    transform: rotatey(0deg) rotate(0deg);
  }
  25% {
    stroke-dashoffset: 11px;
    transform: rotatey(180deg) rotate(270deg);
  }
  49.9999% {
    stroke-dashoffset: 33px;
    transform: rotatey(180deg) rotate(270deg);
  }
  50% {
    stroke-dashoffset: 33px;
    transform: rotatey(0deg) rotate(180deg);
  }
  74.9999% {
    stroke-dashoffset: 11px;
    transform: rotatey(0deg) rotate(180deg);
  }
  75% {
    stroke-dashoffset: 11px;
    transform: rotatey(180deg) rotate(90deg);
  }
  100% {
    stroke-dashoffset: 33px;
    transform: rotatey(180deg) rotate(90deg);
  }
}

@keyframes :global(element-shake) {
  0% {
    transform: translate(0)
  }
  20% {
    transform: translate(-5px)
  }
  40% {
    transform: translate(5px)
  }
  60% {
    transform: translate(-5px)
  }
  80% {
    transform: translate(5px)
  }
  100% {
    transform: translate(0)
  }
}
