.pageBody {
  min-height: 60vh;
  position: relative;
}

.loadingBody {
  min-height: 100vh;
}

/* excessive rules to support ie11 */
.loading {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  width: 100%;
}
