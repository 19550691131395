@media (--tabletMedium) {
  .pageContainer {
    transition: transform 0.2s ease;

    &.pulloutOpen {
      height: 100vh;
      overflow: hidden;
    }
  }
}

.accessibilityMode {
  a:focus-visible,
  button:focus-visible,
  input:focus-visible,
  select:focus-visible {
    @mixin focusVisible;
  }
}

.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 30;
}
