
.container {
  composes: bodyXXS lineHeightOne from '~css/typography.css';
  height: var(--cardBadgeSize);
  text-align: center;
  width: var(--cardBadgeSize);

  @media (--tabletMedium) {
    height: var(--cardBadgeSizeMobile);
    width: var(--cardBadgeSizeMobile);
  }
}

.text {
  composes: flexCenter from '~css/alignment.css';
  border-radius: 50%;
  height: 100%;
}

html[dir='ltr'] {
  .container {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

html[dir='rtl'] {
  .container {
    &:not(:last-of-type) {
      margin-left: 10px;
    }
  }
}
