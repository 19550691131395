.flexCenterHorizontal {
  display: flex;
  justify-content: center;
}

.flexCenterVertical {
  display: flex;
  align-items: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.absoluteVertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.absoluteHorizontal {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
