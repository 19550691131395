.tabSelectWrapper {
  display: flex;
  flex-direction: column;
}

.tabSelectButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  box-shadow: 0 0 0 1px rgb(214, 214, 214) inset;
  cursor: pointer;
  background-color: white;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.tabSelectButton:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.tabSelectButton:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: 1px solid rgb(214, 214, 214);
}

.tabSelectButton.tabSelectButtonActive {
  box-shadow: 0 0 0 2px black inset;
}

.countrySwitchInputLabel {
  color: rgb(214, 214, 214);
  font-size: 14px;
  margin-bottom: 10px;
}
