.container {
  composes: flexCenterVertical from '~css/alignment.css';
  margin-bottom: 1rem;
}

/* Fontsize Prop */

.small {
  composes: bodySmall from '~css/typography.css';
}

.medium {
  composes: bodyMedium from '~css/typography.css';
}

html[dir='ltr'] {
  .iconWrapper,
  .imageWrapper {
    margin-right: 1rem;
  }
}

html[dir='rtl'] {
  .iconWrapper,
  .imageWrapper {
    margin-left: 1rem;
  }
}