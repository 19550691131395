.swatchWrapper {
  composes: lineHeightOne from '~css/typography.css';
  border: 2px solid rgba(234, 234, 234, 1);
  display: inline-block;


  &.selected {
    border-color: black;
  }

  &.fill {
    display: block;
    padding-bottom: calc(100% - 4px);
    position: relative;
    width: 100%;
  }

  &.disabled {
    position: relative;
    border-color: var(--bodyBackgroundColor);

    &:after {
      content: "";
      display: block;
      background-color: var(--bodyBackgroundColor);
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      transform: rotate(-45deg) translateX(-50%);
      width: 1px;
    }
  }
}

.circle {
  border-radius: 50%;
  border-width: 1px;
  border: 1px solid rgba(234, 234, 234, 1);

  &.fill {
    padding-bottom: calc(100% - 2px);
  }
}
