.checkIcon {
  composes: flexCenter from '~css/alignment.css';
  composes: ftIconCheck from '~css/typography.css';
  flex-shrink: 0;
  height: var(--checkboxSize);
  width: var(--checkboxSize);
  opacity: 0;
}

/* color Props */

.black {
  background-color: black;
  color: white;
}

.white {
  background-color: white;
  color: black;
}

/* roundedCorners Props */

.roundedCorners {
  border-radius: var(--standardInputBorderRadius);
}

html[dir='ltr'] {
  .checkIcon {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

html[dir='rtl'] {
  .checkIcon {
    &:not(:last-child) {
      margin-left: 10px;
    }
  }
}
