.container {
  composes: flexCenterVertical from '~css/alignment.css';
  margin-top: 20px;
  padding: 10px;
}

.preorder {
  border: 1px solid var(--colorAvailabilityPreorder);

  .icon {
    color: var(--colorAvailabilityPreorder)
  }
}

.backorder {
  border: 1px solid var(--colorAvailabilityBackorder);

  .icon {
    color: var(--colorAvailabilityBackorder)
  }
}

.basket {
  border: 0;
  padding: 0;
}

.icon {
  composes: flexCenterVertical from '~css/alignment.css';
}

.text {
  composes: bodySmall from '~css/typography.css';
  composes: lineHeightSmall from '~css/typography.css';
  
  .textItem {
    display: block;
  }

  .textDates {
    font-weight: 700;
  }
}

.existingOrder {
  padding: 6px;
  composes: roundedSides from '~css/utils.css';
  border: 0;
  background-color: var(--backgroundColorAvailabilityExistingOrder);

  .text {
    font-weight: 700;
  }

  .textItem {
    display: inline;
  }
}


html[dir='ltr'] {
  .basket {
    margin-right: 0;
  }

  .icon {
    margin-right: 10px;
  }

  .existing-order {
    .textItem:first-child {
      margin-left: 0.3rem
    }
  }
}

html[dir='rtl'] {
  .basket {
    margin-left: 0;
  }

  .icon {
    margin-left: 10px;
  }

  .existing-order {
    .textItem:first-child {
      margin-right: 0.3rem
    }
  }
}