.iconWrapper {
  composes: flexCenter from '~css/alignment.css';
  overflow: hidden;
}

.circle {
  border-radius: 50%;
}

html[dir='ltr'] {
  .navItem {
    > svg {
      margin-left: auto;
    }
  }

  .nextLevelIcon {
    margin-left: auto;
  }

  .subLabel {
    margin-left: 0.5rem;
  }

  .iconWrapper {
    margin-right: 2rem;
  }
}

html[dir='rtl'] {
  .navItem {
    > svg {
      margin-right: auto;
    }
  }

  .nextLevelIcon {
    margin-right: auto;
  }

  .subLabel {
    margin-right: 0.5rem;
  }
  .iconWrapper {
    margin-left: 2rem;
  }
}
